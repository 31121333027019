<template>
  <div>
    <b-row>
      <b-col cols="12">
        <d-select-input-search
          :options="clubs"
          :place-holder="$t('admin.duplicate.search-club-without-model')"
          class-name="bg-white mt-3 mb-3 w-50"
          icon="fa fa-search"
        />
      </b-col>
      <b-col align="right">
        <div class="has-search">
          <span class="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            class="form-control"
            v-model="filter"
            :placeholder="$t('components.doinsport.table.search')"
          >
        </div>
      </b-col>
    </b-row>
    <d-table
      identifier="category-shop"
      :tablefields="fields"
      :items="items"
      :per-page="itemsPerPage"
      :totalRows="totalRows"
      :is-busy="isBusy"
      :per-page-enabled="true"
      :tr-cursor-pointer="false"
      @per-page:update="onPerPageUpdate"
      @on:data-table-btn:checkbox="onDataTableCheck"
      @current-page:update="onPageUpdate"
    >
    </d-table>
    <div class="d-flex justify-content-end">
      <d-button
        :text="'admin.duplicate.proceed-to-edit'"
        class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
        @on:button-click="onProceed"
      />
    </div>
  </div>
</template>
<script>
import {getAllCategoriesProducts, getAllProducts} from "@api/services/products/products.api";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  data: () => ({
    items: [],
    filter: '',
    isBusy: false,
    check: false,
    checkedCategory: null,
    timer: null,
    itemsPerPage: 10,
    totalRows: 1,
    currentPage: 1,
  }),
  props: {
    reload: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: ''
    },
    isArchived: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    reload: function () {
      this.loadShopProducts(this.itemsPerPage, this.currentPage, this.filter);
    },
    filter: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadShopProducts(this.itemsPerPage, this.currentPage, this.filter);
      }, 400);
    }
  },
  computed: {
    selectedCategory() {
      return this.checkedCategory ? this.$t('admin.duplicate.shop-category.selected-shop-cat', {
        cat: this.checkedCategory.name,
        club: 'YouJump',
        name: this.checkedCategory.club.name
      }) : this.$t('admin.duplicate.shop-category.select-shop-cat');
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    clubs() {
      return this.$store.getters['club/getClubs']
    },
    fields() {
      return [
        {
          key: "name",
          label: this.$t('admin.duplicate.shop-category.title'),
          sortable: true,
        },
        {
          key: "shortDescription",
          label: this.$t('admin.duplicate.shop-category.description'),
          sortable: true
        },
        {
          key: "club.name",
          label: this.$t('admin.duplicate.shop-category.club'),
        },
        {
          key: "actions",
          label: this.$t("general.actions.actions"),
        },
      ];
    },
    getCheckedCategoryList() {
      return this.$store.getters['duplicate/getCheckedCategoryList'];
    },
    getSelectedCategory() {
      return this.$store.getters['duplicate/getSelectedCategoryModel'];
    },
  },
  methods: {
    onProceed() {
      const checkedItems = [];

      for (const item of this.items) {
        if (item.checked) {
          checkedItems.push(item);
        }
      }

      this.$store.commit('duplicate/setCheckedCategoryList', checkedItems);
      this.$nextTick(() => {
        this.$router.push({name: 'duplicate_product_category'});
      });
    },
    onDataTableCheck($event) {
      if ($event.checked) {
        this.$store.commit('duplicate/addToCategoryList', $event)
      } else {
        this.$store.commit('duplicate/removeFromCategoryList', $event)
      }
      this.$emit('reload-duplicate-table');
    },
    onCheckAll() {
      this.check = !this.check;
      const checkedItems = [];

      for (const item of this.items) {
        item.checked = this.check;

        if (item.checked) {
          checkedItems.push(item);
        }
      }

      this.$store.commit('duplicate/setCheckedCategoryList', checkedItems);
      this.loadShopProducts(this.itemsPerPage, this.currentPage, this.filter);
    },
    onPageUpdate(page) {
      this.loadShopProducts(this.itemsPerPage, page, this.filter);
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.loadShopProducts(itemsPerPage, this.currentPage, this.filter);
    },
    loadShopProducts(itemsPerPage, currentPage, filter) {
      this.items = [];
      this.isBusy = true;
      const filterTag = 'asc';

      getAllProducts(itemsPerPage, currentPage, filter, filterTag)
        .then((response) => {

          this.totalRows = response.data['hydra:totalItems'];

          for (const item of response.data['hydra:member']) {
            item.actions = ['checkbox'];

            const checkIfChecked = this.getCheckedCategoryList.find(el => el.id === item.id);

            if (isNotUndefinedAndNotNull(checkIfChecked)) {
              item.checked = true;
            }

            if (this.getSelectedCategory && this.getSelectedCategory.club.id !== item.club.id && item.category.id === this.getSelectedCategory.id) {
              item.checked = null;
            }

            this.items.push(item);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
  },
  created() {
    this.loadShopProducts(this.itemsPerPage, this.currentPage, this.filter);
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/_b-card.scss";

/deep/ .card .card-body {
  padding-left: 25px;
  padding-right: 25px;
}

/deep/ .page-item.active .page-link {
  z-index: 0;
  color: #fff;
  background-color: #092772;
  border-color: #092772;
}
</style>
