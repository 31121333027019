import {_get} from "@api/services/httpService";

const URL = '/products';
const URL_CATEGORIES = '/products/categories';

export const PRODUCT_URL_PHOTOS = `${URL}/photos`;

export const getAllProducts = (itemsPerPage = 10, page = 1, filter = '', order = null, props = '') => {
  let url = URL +
    '?itemsPerPage=' + itemsPerPage +
    '&page=' + page;
  if ('undefined' !== typeof (filter) && filter !== '')
    url += '&search=' + filter;
  if ('undefined' !== typeof (order) && order)
    url += '&order[name]=' + order;
  return _get(url);
}

export const getAllCategoriesProducts = (itemsPerPage = 10, page = 1, filter = '', order = null, props = '', clubId = '') => {
  let url = URL_CATEGORIES +
    '?itemsPerPage=' + itemsPerPage +
    '&page=' + page;
  if ('undefined' !== typeof (filter) && filter !== '')
    url += '&search=' + filter;
  if ('undefined' !== typeof (order) && order)
    url += '&order[name]=' + order;

  if ('undefined' !== typeof (clubId) && clubId && clubId !== '')
    url += '&club.id=' + clubId;

  return _get(url);
}
